.aside {
  border: 1px solid $border-color;
  padding: 25px;

  &:not(:last-child) {
    margin-bottom: 30px;
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 18px;
  }

  &__list {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
  }

  &__list-item {
    &:not(:last-child) {
      margin-bottom: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid $border-color;
    }
  }
}

.as-search {
  .form-control {
    border-radius: 0;
    border-right: 0;

    &:focus {
      border-color: #ccc;
    }
  }

  .input-group-text {
    border-radius: 0;
    border-left: 0;
    background-color: $white;
  }
}

.activate {
  &__title {
    font-size: 30px;
    font-weight: 900;
    text-align: center;
    margin-bottom: 3px;
  }

  &__subtitle {
    font-size: 24px;
    font-weight: 900;
    text-align: center;
  }

  &__info {
    margin-bottom: 20px;

    p {
      margin-bottom: 4px;
    }
  }

  &__form {
    width: 550px;
    max-width: 100%;
    margin: 0 auto 10px;

    .form-control {
      font-size: 18px;
      text-align: center;
    }

    .input-group-text {
      color: $white;
      background-color: $primary;
      font-size: 20px;
    }
  }

  &__support {
    font-size: 20px;
    text-align: center;

    a,
    a:hover {
      white-space: nowrap;
      color: inherit;
    }
  }
}

// style the checkbox
.checkbox-styled {
  display: block;
  position: relative;
  cursor: pointer;
  margin-bottom: 0;
  padding-left: 30px;
  &__input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  &__icon {
    width: 18px;
    height: 18px;
    font-size: 18px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    position: absolute;
    top: 1px;
    left: 0;
    &::before {
      content: '\f00c';
      font-family: 'fontawesome';
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      font-size: 13px;
      transform: scale(0);
    }
  }
  &:hover &__icon {
    border-color: $primary;
  }
  &__input:checked + &__icon {
    border-color: $primary;
    &::before {
      transform: scale(1);
      transition: transform 0.2s cubic-bezier(0.68, -0.75, 0.265, 1.75);
    }
  }
}

// style the radio
.radio-styled {
  display: block;
  cursor: pointer;
  margin-bottom: 0;
  position: relative;
  padding-left: 30px;
  &__input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  &__icon {
    width: 18px;
    height: 18px;
    font-size: 18px;
    border: 1px solid #cccccc;
    border-radius: 50%;
    position: absolute;
    top: 1px;
    left: 0;
    &::before {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: scale(0) translate(-50%, -50%);
      background-color: $primary;
      border-radius: 50%;
    }
  }
  &:hover &__icon {
    border-color: $primary;
  }
  &__input:checked + &__icon {
    border-color: $primary;
    &::before {
      transform: scale(1) translate(-50%, -50%);
      transition: transform 0.2s cubic-bezier(0.68, -0.75, 0.265, 1.75);
    }
  }
}

.quantity {
  display: inline-flex;
  width: 100px;
  max-width: 100%;

  &__btn {
    width: 28px;
    border: 1px solid #ccc;
    cursor: pointer;
    position: relative;
    user-select: none;

    &::after {
      content: '\f068';
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      font-family: 'fontawesome';
      font-size: 8px;
    }

    &:first-child {
      border-radius: 14px 0 0 14px;
      border-right: 0;
    }

    &:not(:first-child) {
      border-radius: 0 14px 14px 0;
      border-left: 0;

      &::after {
        content: '\f067';
      }
    }
  }

  &__input {
    flex: 1;
    height: 28px;
    min-width: 20px;
    max-width: 44px;
    border: 1px solid #ccc;
    border-left: 0;
    border-right: 0;
    text-align: center;
  }
}

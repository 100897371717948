.contact {
  &__title {
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__info {
    margin-bottom: 55px;
    a {
      color: inherit;

      &:hover {
        color: inherit;
      }
    }
  }

  &__map {
    height: 255px;
  }
}

.banner {
  display: flex;
  width: 100%;
  height: 200px;
  align-items: center;
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: $primary;
    opacity: 0.6;
  }

  &__bg {
    @include img-cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
    object-position: 100% 50%;
  }

  &__title {
    font-size: 48px;
    font-weight: 700;
    text-align: center;
    color: $white;
    text-transform: uppercase;
  }

  @media (max-width: 767px) {
    height: 100px;

    &__title {
      font-size: 30px;
    }
  }
}

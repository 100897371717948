.rating-stars {
  &::before {
    content: '\f005 \f005 \f005 \f005 \f005';
    font-family: 'fontawesome';
    letter-spacing: 2px;
  }

  &--0::before {
    content: '\f006 \f006 \f006 \f006 \f006';
  }

  &--0-5::before {
    content: '\f123 \f006 \f006 \f006 \f006';
  }

  &--1::before {
    content: '\f005 \f006 \f006 \f006 \f006';
  }

  &--1-5::before {
    content: '\f005 \f123 \f006 \f006 \f006';
  }

  &--2::before {
    content: '\f005 \f005 \f006 \f006 \f006';
  }

  &--2-5::before {
    content: '\f005 \f005 \f123 \f006 \f006';
  }

  &--3::before {
    content: '\f005 \f005 \f005 \f006 \f006';
  }

  &--3-5::before {
    content: '\f005 \f005 \f005 \f123 \f006';
  }

  &--4::before {
    content: '\f005 \f005 \f005 \f005 \f006';
  }

  &--4-5::before {
    content: '\f005 \f005 \f005 \f005 \f123';
  }

  &--5::before {
    content: '\f005 \f005 \f005 \f005 \f005';
  }
}

.header {
  height: 75px;

  &__wrapper {
    background-color: $white;
    position: fixed;
    z-index: 1020;
    top: 0;
    left: 0;
    right: 0;
    box-shadow: 0 1px 10px rgba(#000, 0.15);
  }

  &__inner {
    display: flex;
    align-items: center;
  }

  &__logo {
    display: inline-flex;

    img {
      height: 75px;
    }
  }

  &__menu-btn {
    margin-left: 16px;
    color: #555;
    border-color: #444;
  }

  &__btns {
    padding: 0 20px 20px;
  }

  &__btn {
    display: block;
    margin-bottom: 10px;
    color: #999;
    border-color: $primary;
  }

  @media (min-width: 1200px) {
    &__navbar {
      flex-grow: 1;
    }

    &__menu-btn {
      display: none;
    }

    &__btns {
      display: flex;
      padding: 0;
      order: 2;
      margin-left: auto;
      margin-right: 10px;
    }

    &__btn {
      margin-bottom: 0;

      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
}

.search {
  padding: 0 20px 20px;

  &__toggle {
    display: none;
  }

  @media (min-width: 1200px) {
    padding: 0;
    order: 1;
    position: relative;

    &__body {
      display: none;
      width: 300px;
      position: absolute;
      z-index: 1;
      top: 0;
      right: 8px;
    }

    &__toggle {
      display: inline-flex;
      width: 55px;
      height: 35px;
      justify-content: center;
      align-items: center;
      border: 0;
      background: 0;
      color: #666;
    }
  }
}

.menu-root {
  & > .menu-item {
    & > .menu-link {
      font-size: 16px;
      font-weight: 700;

      &.active,
      &.active ~ .menu-toggle {
        color: $primary;
      }
    }
  }

  @media (min-width: 1200px) {
    flex-grow: 1;

    & > .menu-item {
      flex-grow: 1;

      & > .menu-link {
        text-align: center;
        line-height: 75px;
      }

      &.menu-item-group {
        & > .menu-link {
          padding-right: 20px;
        }

        & > .menu-toggle {
          top: auto;
          right: auto;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          span {
            position: relative;

            &,
            &::before,
            &::after {
              content: '';
              display: block;
              width: 5px;
              height: 5px;
              border-radius: 50%;
              background-color: currentColor;
            }

            &::before {
              position: absolute;
              top: 0;
              left: -10px;
            }

            &::after {
              position: absolute;
              top: 0;
              right: -10px;
            }

            .fa {
              display: none;
            }
          }
        }
      }
    }
  }
}

.cart-btn {
  display: inline-flex;
  width: 40px;
  height: 35px;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-left: auto;

  span {
    display: inline-flex;
    width: 22px;
    height: 22px;
    justify-content: center;
    align-items: center;
    background-color: $primary;
    color: $white;
    border-radius: 50%;
    position: absolute;
    top: -4px;
    right: -2px;
  }

  @media (min-width: 1200px) {
    order: 1;
  }
}

.about-banner {
  display: flex;
  height: 200px;
  align-items: center;
  position: relative;
  z-index: 1;

  &__bg {
    @include img-cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
  }

  &__title {
    color: $white;
    text-align: center;
    font-size: 20px;
    font-weight: 900;
  }

  &__subtitle {
    text-align: center;
    color: $white;
    font-size: 60px;
    font-weight: 900;
    margin-top: 5px;
  }

  @media (max-width: 991px) {
    &__title {
      font-size: 16px;
    }

    &__subtitle {
      font-size: 40px;
    }
  }

  @media (max-width: 991px) {
    &__title {
      font-size: 16px;
    }

    &__subtitle {
      font-size: 24px;
    }
  }
}

.about {
  padding: 40px 0;
  &-title {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
  }
}

.about-app {
  background-color: #f9f9f9;

  &__iwrap {
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    padding-top: 30px;
  }

  &__content {
    padding: 30px 0 20px;
  }

  &__title {
    font-size: 30px;
    font-weight: 900;
    text-transform: uppercase;
    color: $primary;
  }

  &__desc {
    margin-bottom: 20px;
    text-align: justify;
  }

  &__feature {
    margin-bottom: 10px;

    i.fa {
      color: $primary;
    }
  }

  @media (max-width: 767px) {
    &__title {
      font-size: 24px;
    }
  }
}

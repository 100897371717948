.course-header {
  background-color: $primary;
  color: $white;
  padding: 35px 0 42px;

  &__title {
    font-size: 30px;
    font-weight: 900;
  }

  &__desc {
    font-size: 14px;
    margin-bottom: 24px;
  }

  &__info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__author {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 30px;
    margin-right: 50px;
  }

  &__avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: $white;
    margin-right: 15px;

    img {
      @include img-cover;
    }
  }

  &__rating {
    margin-right: 50px;
  }

  &__rating-stars {
    font-size: 18px;
    line-height: 21px;
    margin-right: 8px;
  }

  @media (max-width: 767px) {
    &__info {
      flex-direction: column;
      align-items: flex-start;
    }

    &__author {
      margin-right: 0;
    }

    &__rating {
      margin: 14px 0;
    }
  }
}

.course-banner {
  display: block;
  width: 100%;
}

.course-section {
  background-color: #f9f9f9;
  padding: 30px;

  &:not(:first-child) {
    margin-top: 15px;
  }

  &__title {
    font-size: 16px;
    font-weight: 700;
  }

  &__feature {
    padding: 10px 0 10px 30px;
    position: relative;

    &::before {
      content: '\f00c';
      font-family: 'fontawesome';
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      color: $primary;
      font-size: 16px;
    }
  }
}

.relate-list {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;

  &__item {
    &:not(:last-child) {
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px dashed #ccc;
    }
  }
}

.course-menu {
  border: 1px solid $border-color;
  border-radius: 4px;
  overflow: hidden;

  &__item {
    border-top: 1px solid $border-color;
  }

  &__btn {
    display: inline-flex;
    width: 40px;
    height: 34px;
    justify-content: center;
    align-items: center;
    color: $gray-800;

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }

  &__title {
    display: inline-block;
    font-size: 14px;
    line-height: 34px;
    color: $gray-800;

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }

  &__time {
    line-height: 34px;
    padding: 0 20px;
  }

  &__header {
    cursor: pointer;

    .fa-minus-circle {
      display: none;
    }

    &.active {
      .fa-minus-circle {
        display: inline;
      }

      .fa-plus-circle {
        display: none;
      }
    }
  }

  &__body {
    display: none;
  }

  &__header &__item {
    border-top: 0;
    background-color: #ebebeb;
    font-weight: 700;
  }
}

.course-detail {
  background-color: $white;
  border: 1px solid $primary;
  border-radius: 4px;
  padding: 16px;

  &__price {
    font-size: 30px;
    font-weight: 900;
    color: #f00;
    margin-bottom: 22px;

    del {
      color: #aaa;
      font-size: 0.8em;
      font-weight: 400;
      margin-left: 10px;
    }
  }

  @media (min-width: 1200px) {
    margin-top: -128px;
  }
}

.course-features {
  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    list-style: none;
    padding-left: 0;

    li {
      flex: 0 0 50%;
      padding: 10px 15px 10px 45px;
      max-width: 50%;
      position: relative;

      &::before {
        content: '\f00c';
        font-family: 'fontawesome';
        display: block;
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
        color: $primary;
        font-size: 16px;
      }
    }
  }

  @media (max-width: 575px) {
    ul {
      li {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
}

.section {
  &__header {
    margin-bottom: 30px;
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 0;
  }

  &__desc {
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    margin-top: 5px;
  }
}

.news-list {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;

  &__item {
    &:not(:last-child) {
      margin-bottom: 14px;
      padding-bottom: 14px;
      border-bottom: 1px dotted $border-color;
    }
  }

  &__more {
    color: #f00;

    &:hover {
      color: #f00;
    }
  }
}

.home-news {
  background-color: #f4f3f3;
  padding: 40px 0 0;
}

.testimonial-slider {
  .swiper-slide {
    padding-bottom: 80px;
  }

  .swiper-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 40px;

    .swiper-pagination-bullet {
      display: block;
      width: 15px;
      height: 15px;
      border-radius: 0;
      background-color: #ebebeb;
      margin: 0 10px;
      opacity: 1;
      transform: rotate(45deg);

      &:focus {
        outline: 0;
      }
    }

    .swiper-pagination-bullet-active {
      background-color: $primary;
    }
  }
}

.pros {
  &__iwrap {
    width: 125px;
    height: 125px;
    border-radius: 50%;
    margin: 0 auto 10px;

    img {
      @include img-cover;
    }
  }

  &__title {
    font-size: 24px;
    text-align: center;
    font-weight: 700;
  }

  &__desc {
    text-align: center;
  }

  @media (max-width: 767px) {
    &__title {
      font-size: 18px;
    }
  }
}

.featured-course {
  background-color: #f4f3f3;
  padding: 40px 0 50px;
}

.course-grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 15px;

  &__item {
    &:first-child {
      grid-row: span 2;
    }
  }

  &__img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    border: 5px solid $primary;
  }

  @media (max-width: 767px) {
    grid-gap: 8px;

    &__img {
      border-width: 2px;
    }
  }
}

.featured-slider {
  position: relative;

  &__prev,
  &__next {
    cursor: pointer;
    position: absolute;
    z-index: 10;
    top: 50%;
    left: -48px;
    transform: translateY(-50%);

    &:focus {
      outline: 0;
    }
  }

  &__next {
    left: auto;
    right: -48px;

    img {
      transform: rotateY(180deg);
    }
  }
}

.banner-slider {
  .swiper-pagination {
    display: flex;
    width: auto;
    justify-content: flex-end;
    position: absolute;
    right: 120px;
    bottom: 24px;

    .swiper-pagination-bullet {
      display: block;
      width: 16px;
      height: 16px;
      background-color: #fff;
      margin: 0 10px;
      opacity: 1;
      border-radius: 0;
      transform: rotate(45deg);
    }

    .swiper-pagination-bullet-active {
      background-color: $primary;
    }
  }
}

.home-intro-slider {
  display: block;
  border: 1px solid $border-color;
  padding: 1px;
  background-color: $white;
  box-shadow: 0 1px 10px rgba(#000, 0.15);
  position: relative;

  &__prev,
  &__next {
    display: flex;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    color: $white;
    background-color: rgba(#000, 0.5);
    cursor: pointer;
    opacity: 0.8;
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);

    &:focus {
      outline: 0;
    }
  }

  &__next {
    left: auto;
    right: 10px;
  }

  &__frame {
    display: block;
    width: 100%;
    position: relative;
    background-color: $white;

    &::before {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 60%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }

  &__desc {
    padding: 8px;
    text-align: center;
  }
}

.home-intro {
  &__iwrap {
    display: block;
    border: 1px solid $border-color;
    padding: 1px;
    background-color: $white;
    box-shadow: 0 1px 10px rgba(#000, 0.15);

    img {
      display: block;
      width: 100%;
    }
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    color: $primary;
  }

  &__desc {
    text-align: justify;
  }

  &__item {
    padding-left: 46px;
    position: relative;

    img {
      position: absolute;
      top: -4px;
      left: 0;
    }
  }
}

.course {
  @include img-shine();
  position: relative;

  &__iwrap {
    @include iwrap(56.25%);
  }

  &__title {
    background-color: $primary;
    color: $white;
    font-size: 16px;
    font-weight: 900;
    text-align: center;
    text-transform: uppercase;
    padding: 8px;
    margin-bottom: 0;
  }

  &__link {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 575px) {
    &__title {
      padding: 4px;
      font-size: 14px;
      font-weight: 700;
    }
  }
}

.course-2 {
  &__iwrap {
    @include iwrap(50%);
    @include img-shine;
    width: 215px;
    margin-right: 20px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    position: relative;
    align-self: stretch;
  }

  &__title {
    padding-right: 150px;
    font-size: 16px;
    font-weight: 900;
    padding-bottom: 8px;
    margin-bottom: auto;

    &:hover {
      color: $primary;
    }
  }

  &__price {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 30px;
    font-weight: 900;
    color: #f00;
  }

  &__info {
    display: flex;
    align-items: center;
    margin-top: 10px;

    .fa {
      color: $primary;
    }
  }

  &__rating-stars {
    font-size: 16px;
    line-height: 21px;
    color: $primary;
    margin-right: 4px;
  }

  &__member {
    margin-left: auto;
  }

  @media (max-width: 767px) {
    &__title {
      padding-right: 0;
    }

    &__price {
      position: static;
    }

    &__info {
      flex-direction: column;
      align-items: flex-start;
    }

    &__member {
      margin-left: 0;
      margin-top: 10px;
    }
  }

  @media (max-width: 575px) {
    flex-direction: column;

    &__iwrap {
      width: 100%;
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
}

.news {
  &__iwrap {
    @include iwrap(80%);
  }

  &__body {
    margin-top: -110px;
    padding: 0 30px 23px;
  }

  &__content {
    background-color: $white;
    box-shadow: 0 3px 20px rgba(#000, 0.15);
    border-radius: 10px;
    padding: 25px 30px 52px;
    position: relative;
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 16px;

    &:hover {
      color: $primary;
    }
  }

  &__desc {
    @include max-line(4);
    text-align: justify;
  }

  &__btn {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(30px, 50%);
  }
}

.news-2 {
  &__iwrap {
    @include iwrap(67%);
    @include img-shine;
    margin-bottom: 18px;
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 4px;

    &:hover {
      color: $primary;
    }
  }

  &__desc {
    @include max-line(3);
    color: #666;
    line-height: 24px;
    max-height: 72px;
  }
}

.news-3 {
  &__iwrap {
    @include iwrap(60%);
    @include img-shine;
    width: 145px;
    margin-right: 14px;
  }

  &__title {
    font-size: 14px;
    font-weight: 600;
    position: relative;
    padding-bottom: 4px;

    &:hover {
      color: $primary;
    }

    &::after {
      content: '';
      display: block;
      width: 80px;
      position: absolute;
      bottom: 0;
      left: 0;
      border-bottom: 1px solid $primary;
    }
  }

  &__desc {
    @include max-line(2);
  }
}

.as-course {
  &__iwrap {
    @include iwrap(100%);
    width: 60px;
    margin-right: 10px;
  }

  &__title {
    margin-top: -1px;
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    margin-bottom: 0;

    &:hover {
      color: $primary;
    }
  }

  &__info {
    color: #666;
  }
}

.as-news {
  &__iwrap {
    @include iwrap(100%);
    width: 60px;
    margin-right: 10px;
  }

  &__title {
    @include max-line(2);
    margin-top: -1px;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 0;

    &:hover {
      color: $primary;
    }
  }

  &__info {
    color: #666;
  }
}

.cart-course {
  &__iwrap {
    @include iwrap(100%);
    width: 60px;
    border: 1px solid $border-color;
  }

  &__title {
    padding: 0 10px;
    font-size: 14px;
    margin-bottom: 0;
    font-weight: 700;

    &:hover {
      color: $primary;
    }
  }

  &__quantity {
    width: 80px;
  }
}

.testimonial {
  padding: 20px 30px;
  border-radius: 4px;
  background-color: #eaf8fe;

  &__text {
    margin-bottom: 10px;
  }

  &__avatar {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 1px 6px rgba(#000, 0.3);
    margin-right: 20px;

    img {
      @include img-cover;
    }
  }

  &__name {
    font-size: 14px;
    line-height: 25px;
    font-weight: 700;
  }

  &__pos {
    font-size: 14px;
    color: #666;
  }
}

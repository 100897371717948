.sidebar {
  &__header {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    color: $white;
    background-color: $primary;
    border: 0;
    padding: 0 15px;
    line-height: 45px;
  }

  &__nav {
    flex-direction: column;

    .nav-item {
      &:not(:last-child) {
        border-bottom: 1px solid $border-color;
      }
    }

    .nav-link {
      padding: 12px 16px;
      color: $gray-800;

      &:hover,
      &.active {
        color: $primary;
        font-weight: 700;
      }

      .fa {
        color: $primary;
      }
    }
  }
}

.profile-section {
  margin-bottom: 20px;
  &__title {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 12px;
    &::after {
      content: '';
      display: block;
      flex: 1;
      border-top: 1px solid $border-color;
      margin-left: 30px;
    }
  }
}

.profile-info {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
  li {
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__number {
    color: inherit;
    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
  &__edit {
    display: inline-block;
    margin-left: 40px;
    text-decoration: underline;
    font-style: italic;
    position: relative;
    color: #4166b0;
    &:hover {
      color: #4166b0;
    }
    &::before {
      content: '\f040';
      font-family: 'fontawesome';
      position: absolute;
      top: 0;
      left: -18px;
    }
  }
}

.profile {
  &__thumbs {
    width: 105px;
    margin-right: 20px;
  }
  &__thumbs-iwrap {
    @include iwrap(110%);
    border: 1px solid $border-color;
    background-color: #f4f4f4;
    margin-bottom: 3px;
    border-radius: 2px;
    overflow: hidden;
  }
  &__thumbs-placeholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    white-space: nowrap;
    text-align: center;
    color: #999;
  }
  &__thumbs-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    cursor: pointer;
    border-radius: 2px;
    background-color: #636363;
    font-size: 11px;
    font-weight: bold;
    color: $white;
    position: relative;
    z-index: 1;
    overflow: hidden;
    .fa {
      margin-right: 4px;
    }
    input {
      position: absolute;
      z-index: -1;
      opacity: 0;
    }
  }
}

.profile-course-tab {
  border-bottom: 1px solid $border-color;
  margin-bottom: 16px;

  .nav-item:not(:last-child) {
    margin-right: 50px;
  }

  .nav-link {
    font-size: 18px;
    font-weight: 700;
    padding: 5px 0;
    position: relative;
    color: $gray-800;

    &.active {
      color: $primary;
    }

    &.active::after {
      content: '';
      display: block;
      width: 100%;
      border-bottom: 3px solid $primary;
      position: absolute;
      bottom: -2px;
      left: 0;
    }
  }
}

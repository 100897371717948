.post {
  font-family: Arial, Helvetica, sans-serif;

  &-title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  &-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }

  &-sapo {
    font-size: 14px;
    font-weight: 700;
    text-align: justify;
  }
}

.footer {
  background-color: $primary;

  &__title {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  &__about {
    a,
    a:hover {
      color: inherit;
    }
  }

  &__nav {
    .nav-item:not(:last-child) {
      margin-bottom: 8px;
    }

    .nav-link {
      display: inline-block;
      padding: 0;
      color: inherit;

      &:hover {
        color: inherit;
        text-decoration: underline;
      }
    }
  }

  &__copyright {
    padding: 10px 0 9px;
    color: $white;
    text-align: center;
    background-color: darken($primary, 10%);
  }

  @media (min-width: 992px) {
    &__smaller-col {
      flex: 0 0 calc(25% - 80px);
      max-width: calc(25% - 80px);
    }

    &__larger-col {
      flex: 0 0 calc(25% + 40px);
      max-width: calc(25% + 40px);
    }
  }
}
